export default {
  text: '#1f2144',
  primary: '#2e3480',
  primaryLight: '#4f53bf',
  primaryDark: '#070b38',
  secondary: '#60bb50',
  secondaryLight: '#a2de98',
  secondaryDark: '#2c801e',
  secondaryDarker: '#114a08',
  background: '#ffffff',
  backgroundSecondary: '#005a87',
  light: '#FFF',
  dark: '#010000'
}
