const customVariants = {
  // ? ==============
  // ? === Layout ===
  // ? ==============

  navigation: {
    '.container': {
      background: ['transparent', '', ''],
      color: 'white',
      border: 'none',
      padding: ['1rem 1.5rem', '', '', '1.5rem 2.5rem']
    },
    '.containerScrolled': {
      backgroundColor: ['background', '', ''],
      color: 'text',
      border: 'none',
      padding: ['0.25rem 1.5rem', '', '', '1rem 1.5rem'],
      '.smallNavMenu': {
        color: 'text'
      }
    },

    '.smallNavMenu': {
      display: ['none', '', '', 'flex'],
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      '.rendez-vous': {
        variant: 'buttons.secondary',
        borderColor: 'secondaryDark',
        backgroundColor: 'secondaryDark',
        p: ['0.5rem 1rem', '0.5rem 1rem', '0.5rem 1rem', '0.5rem 1rem'],
        borderRadius: '100px',
        ml: '1rem',
        ':hover': {
          backgroundColor: 'backgroundSecondary',
          borderColor: 'backgroundSecondary',
          color: 'white',
          a: {
            color: 'inherit'
          }
        }
      }
    },

    '.hamburger, .hamburgerOpen': {
      display: ['', '', '', 'none'],
      color: 'inherit',
      ml: '2rem',
      mr: '0rem',
      border: 'solid 1px',
      borderColor: 'currentColor',
      height: ['40px', '', '45px'],
      width: ['40px', '', '45px'],
      p: ['7px', '', '8px'],
      div: {
        backgroundColor: 'currentColor',
        borderRadius: '8px',
        height: ['3px', '', '3px'],
        overflow: 'hidden',
        border: 'none'
      }
    },
    '.hamburgerOpen': {
      color: 'white'
    },

    '.navItem': {
      margin: ['0.3rem', '', '', '0.6rem'],
      a: {
        color: ['inherit', '', '', 'inherit'],
        fontWeight: 'bold',
        letterSpacing: '1px',
        fontSize: ['1rem', '', '', '0.86rem'],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // animation for nav items
        '::after': {
          content: '""',
          display: 'block',
          width: '0px',
          height: '2px',
          backgroundColor: 'primaryLight',
          letterSpacing: '0.1em',
          textAlign: 'center',
          transition: '0.2s ease-in-out'
        },
        ':hover': {
          color: 'secondaryDark',

          '::after': {
            color: 'inherit',
            position: 'relative',
            left: '0px',
            width: '100%'
          }
        }
      }
    },
    '.logoLocationContainer': {
      padding: '0rem',
      position: 'static',
      mr: 'auto',
      '.logo, .logoScrolled': {
        position: 'static',
        transform: 'unset',
        display: 'flex',
        width: 'fit-content',
        a: {
          display: 'flex',
          width: 'fit-content'
        },
        img: {
          filter: 'unset'
        }
      },
      '.logo': {
        maxHeight: '120px'
      },
      '.logoScrolled': {}
    },

    // ? === Nav Menu ===

    '.navMenu, .navMenuOpen': {
      color: 'white',
      backgroundColor: 'primaryDark',
      padding: ['2rem', '', '', '2rem'],
      width: ['100%', '100%', '100%', '100%'],
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      transform: 'unset',
      position: 'fixed',
      left: '0rem',
      right: 'unset',
      '.navItem': {
        textAlign: 'left',
        margin: ['0.5rem', '0.5rem', '0.5rem', '0.5rem', '0.5rem'],
        a: {
          fontSize: ['1.5rem', '1.75rem', '2rem', '2rem', '2.25rem'],
          letterSpacing: '0px',
          fontWeight: 'bold',
          color: 'white',
          transition: 'all ease-in-out 0.7s',
          textDecoration: 'none',
          ':hover': {
            color: 'secondaryDark'
          }
        }
      },
      '.seperatorLine': {
        display: 'none'
      },
      '.navItemDropdownMenu': {
        position: 'static',
        background: 'transparent',
        textShadow: 'unset'
      }
    },

    '.navMenu': {
      width: ['85%', '60%', '50%', '40%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      transform: 'unset',
      position: 'fixed',
      color: 'white',
      backgroundColor: 'transparent',
      height: '100vh',
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      '.navItem': {
        textAlign: 'left',
        margin: ['0.5rem', '0.5rem', '0.5rem', '0.5rem', '0.5rem'],
        a: {
          fontSize: ['1.5rem', '1.75rem', '2rem', '2rem', '2.25rem'],
          letterSpacing: '0px',
          fontWeight: 'bold',
          color: 'white',
          transition: 'all ease-in-out 0.7s',
          textDecoration: 'none',
          ':hover': {
            opacity: '0.5'
          }
        }
      }
    },

    '.navBlock': {
      width: ['15%', '40%', '50%', '60%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      height: '100vh',
      position: 'fixed'
    },
    '.navBlockOpen': {
      position: 'fixed',
      backdropFilter: 'blur(1000042px)',
      backgroundImage: 'url(https://img.freepik.com/free-vector/soft-blurred-background_1034-273.jpg?w=2000)',
      backgroundColor: '#000000',
      opacity: 0.98,
      backgroundSize: 'cover',
      filter: 'brightness(0.1)'
    },
    '.phoneSocialContainer': {
      mt: ['2rem'],
      flexDirection: 'column',
      alignItems: 'flex-start',
      color: 'secondaryDark',

      '> li': {
        width: 'fit-content',
        fontWeight: 'bold',

        a: {
          fontSize: '20px'
        },
        '.fa-phone': {
          mr: '1rem'
        },
        '.socialLink': {
          svg: {
            width: '26px',
            height: '26px'
          }
        }
      }
    },
    '.navMenuLogo': {
      maxWidth: ['170px', '', '240px', '', '260px'],
      display: 'flex',
      flexDirection: 'column',

      '::after': {
        mt: '2rem',
        content: "'Select'",
        padding: '0.5rem',
        mb: '1.5rem',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        borderBottom: '1px solid',
        textTransform: 'uppercase',
        color: 'secondaryDark'
      }
    },

    '.smallNavHeading': {
      display: 'none'
    }
  },

  footer: {
    backgroundColor: 'primaryDark',
    color: 'white',
    padding: ['2rem 0rem', '', '3rem 4rem'],
    paddingBottom: ['6rem', '', '', '1rem'],
    maxWidth: 'unset',
    alignItems: 'flex-start',
    '.image': {
      margin: '1rem',
      maxHeight: '60px'
    },
    '.multiButtonContainer': {
      width: ['100%', '', '', '40%'],
      margin: ['2rem 0rem', '', '0.5rem'],
      justifyContent: 'flex-start',
      a: {
        color: 'light',
        border: 'unset',
        letterSpacing: '1px',
        fontWeight: '900',
        padding: '0.25rem',
        textAlign: 'left',
        ':hover': {
          color: 'white',
          filter: 'unset',
          backgroundColor: 'primary'
        }
      }
    },
    '.bottomBar': {
      borderRadius: '0px',
      borderWidth: '2px',
      padding: '1rem',
      alignItems: ['flex-start', '', '', 'center'],
      '> .gonationLogo': {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        margin: '0rem',
        padding: '1rem',
        svg: {
          filter: 'brightness(0) invert(1)'
        },
        '.poweredByText': {
          justifyContent: 'flex-start'
        }
      }
    },
    '.contactDetails-container': {
      textAlign: 'left'
    },
    '.socialContainer': {
      margin: '0.75rem',
      svg: {
        path: {
          fill: 'white'
        },
        border: 'solid 2px white',
        padding: '10px',
        width: '50px',
        height: '50px',
        borderRadius: '100px',
        ':hover': {
          backgroundColor: 'white',
          path: {
            fill: 'primary'
          }
        }
      }
    },

    '.quote': {
      fontStyle: 'unset',
      color: '#65c3f1',
      borderTop: 'solid 5px #e8e8e8',
      borderBottom: 'solid 5px #e8e8e8',
      textAlign: 'center'
    },
    '.column': {
      width: 'fit-content',
      padding: '0rem 1.5rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    '.copyright': {
      padding: '1rem',
      display: 'flex',
      whiteSpace: ['', '', '', '', '', 'nowrap']
    },
    '.gonationLogo': {
      a: {
        justifyContent: ['flex-start', '', '', 'flex-end']
      }
    }
  },

  ctaWidget: {
    zIndex: '999',
    backgroundColor: 'transparent',
    color: 'light',

    a: {
      backgroundColor: 'primary',
      fontFamily: 'heading',
      fontWeight: '700'
    }
  },

  pageHero: {
    padding: '40vh 6% 6rem',
    backgroundColor: 'primary',
    height: '70vh',
    paddingBottom: '6rem',
    position: 'relative',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    '.title': {
      color: 'primary',
      textAlign: 'left',
      padding: '1rem 2rem',
      backgroundColor: 'white',
      borderRadius: '0px 20px 20px 0px'
    },
    '::before': {
      content: "''",
      width: '5px',
      height: '35%',
      backgroundColor: 'white',
      position: 'absolute',
      left: '6%',
      bottom: '0rem'
    },

    '::after': {
      content: "''",
      borderLeft: '2px solid white',
      borderBottom: '2px solid white',
      transform: 'rotate(-45deg)',
      transformOrigin: 'center',
      width: '20px',
      height: '20px',
      position: 'absolute',
      left: 'calc(50% - 10px)',
      bottom: '1rem'
    }
  },

  // ? ==================
  // ? === reuseables ===
  // ? ==================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
    fontWeight: 'bold',
    // textTransform: 'capitalize',
    color: 'secondaryDark'
  },

  subtitle: {
    fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
    fontWeight: '600',
    color: 'primary'
  },

  subtext: {
    fontWeight: '400'
  },

  text: {
    fontWeight: '300',
    fontSize: '1rem',
    lineHeight: '1.7',
    color: 'text'
  },

  homepageSideBySide1: {
    position: 'relative',
    backgroundColor: 'transparent',
    color: 'text',
    zIndex: '2',
    flexDirection: ['column', '', 'row'],
    padding: ['5%', '', '7.5% 5%'],
    '.lazyload-wrapper': {
      position: 'relative',
      img: {
        borderRadius: '20px'
      }
    },
    '.title': {
      variant: 'text.title',
      mb: '2rem'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      mb: '2rem'
    },
    '.text': {
      variant: 'text.text',
      marginBottom: '1.5rem'
    },
    '.content': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: ['2rem 1rem', '', '2rem', '4rem']
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      backgroundColor: 'secondaryDark'
    }
  },

  // ? =================
  // ? === homepage ====
  // ? =================

  homepageHero: {
    color: 'white',
    zIndex: '2',
    '.section': {
      padding: '1.5rem'
    },
    '.title': {
      variant: 'text.title',
      fontSize: ['2.5rem', '2.5rem', '3rem', '3.5rem'],
      color: 'inherit'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      fontSize: ['1.15rem', '1.3rem', '1.75rem', '2rem'],
      color: 'secondaryDark'
    },
    '.text': {
      variant: 'text.text',
      color: 'inherit',
      p: {
        fontSize: ['0.9rem', '1rem']
      },
      mb: '2rem'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    },
    '.slick-slider .slick-slide img': {
      objectPosition: ['34%', '', '30%', '20%', 'center'],
      filter: ['brightness(0.6)']
    },

    '.slick-arrow': {
      display: 'none !important'
    }
  },

  homepageBar: {
    backgroundColor: 'primaryDark',
    padding: ['1rem 0rem', '', '0rem'],
    '.section': {
      padding: ['0rem 1rem', '', '0rem 0vw 0rem 0rem', '0rem 5vw'],
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '100%',
      width: '100%',
      '.title': {
        variant: 'customVariants.title',
        color: 'white',
        order: '1',
        fontSize: ['1.1rem', '', '1.2rem', '1.25rem'],
        p: ['0.5rem', '', '', '1rem'],
        mb: '0rem'
      },
      '.subtitle': {
        variant: 'customVariants.title',
        order: '2',
        color: 'white',
        fontSize: ['1.1rem', '', '1.2rem', '1.25rem'],
        p: ['0.5rem', '', '', '1rem'],
        mb: '0rem'
      },
      '.linksContainer': {
        order: '3',
        mb: '0rem',
        ml: ['1rem', '', '', '2rem', '3rem']
      }
    }
  },

  shoutSection: {
    flexDirection: ['column', 'column', 'row', ''],
    alignItems: ['flex-start', '', '', 'center'],
    position: 'relative',
    padding: '1rem 1rem 3rem',
    overflow: 'hidden',
    backgroundColor: 'primary',
    color: 'white',
    '.imageContainer': {
      width: ['100%', '100%', '40%', '25%'],
      border: ['13px solid'],
      borderColor: ['white'],
      borderRadius: ['30px', '', '', '30px'],
      overflow: 'hidden',
      zIndex: '3',
      marginBottom: ['1rem', '', '0rem'],
      '.imageFill': {
        paddingBottom: '100%'
      }
    },
    '.content': {
      padding: ['1rem', '', '2rem', '2rem 2rem 2rem 6rem', '2rem 2rem 2rem 6rem'],
      width: ['100%', '100%', '40%', '75%'],
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: ['', '', '-4%'],
      position: 'relative',
      color: 'white'
    },
    '.title': {
      variant: 'text.subtitle',
      textAlign: 'left',
      fontSize: ['1.5rem', '1.75rem'],
      color: 'inherit',
      margin: '0rem',
      display: 'none',
      order: '1'
    },
    '.text': {
      variant: 'text.title',
      margin: '0rem',
      maxWidth: 'unset',
      width: '100%',
      fontSize: ['1.5rem', '1.6rem', '2rem', '2.5rem', '2.75rem'],
      textAlign: 'left',
      order: '1',
      color: 'white'
    },

    '.date': {
      fontSize: '1rem',
      order: '2',
      maxWidth: 'unset',
      textAlign: 'left',
      margin: '1rem 0rem',
      color: 'inherit'
    },
    '.shoutCTABtns': {
      order: '4'
    },
    '.shoutCTA': {
      variant: 'buttons.primary',
      backgroundColor: 'secondaryDark',
      margin: '1rem 1rem 1rem 0rem'
    }
  },

  // ? ========================
  // ? === content Sections ===
  // ? ========================

  homepageCenterBlock: {
    padding: '20vh 2rem 20vh',
    backgroundColor: 'primaryLight',
    textAlign: 'center',
    color: 'white',
    '.section': {
      maxWidth: '1100px'
    },
    '.title': {
      variant: 'text.title',
      fontSize: ['2.5rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      mb: '2rem',
      order: '1',
      color: 'white'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      mb: '2rem',
      order: '2',
      color: 'white'
    },
    '.text': {
      variant: 'text.text',
      order: '3',
      mb: '2rem'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      backgroundColor: 'secondaryDark',
      borderColor: 'secondaryDark',
      ':hover': {
        backgroundColor: 'primary',
        borderColor: 'primary'
      }
    }
  },

  // ? =================
  // ? === About Us ====
  // ? =================

  about1: {
    variant: 'customVariants.homepageSideBySide1',
    backgroundColor: 'transparent',
    color: 'text',
    'div.content': {
      width: ['100%', '', '55%', '60%']
    },
    'div.lazyload-wrapper': {
      width: ['100%', '', '45%', '40%'],
      height: 'fit-content',
      img: {
        objectFit: 'contain'
      }
    }
  },

  // ? =======================
  // ? === About Services ====
  // ? =======================

  about1: {
    variant: 'customVariants.homepageSideBySide1'
  },

  // ? ==================
  // ? ===  Services ====
  // ? ==================

  services1: {
    variant: 'customVariants.homepageSideBySide1'
  },
  services2: {
    variant: 'customVariants.homepageSideBySide1'
  },
  services3: {
    variant: 'customVariants.homepageSideBySide1'
  },
  services4: {
    variant: 'customVariants.homepageSideBySide1'
  },

  // ? ==================
  // ? === Promotions ===
  // ? ==================

  promotions: {
    variant: 'customVariants.homepageContentSection1',
    padding: '5vh 1rem 5vh'
  },

  // ? ===============
  // ? === gallery ===
  // ? ===============

  gallery: {
    '.albumName': {
      display: 'none'
    },
    '.albumImage': {
      border: 'solid 5px white',
      boxShadow: '2px 2px 10px grey',
      borderRadius: '20px'
    }
  },

  // ? ===============
  // ? === Contact ===
  // ? ===============

  locationMap: {
    '.content_container': {
      backgroundColor: '#e6f7ff',
      color: 'black'
    },
    '.hours-section': {
      display: 'none'
    },
    '.title': {
      variant: 'text.title',
      fontSize: ['1.5rem', '', '1.75rem'],
      color: 'primary',
      textTransform: 'uppercase'
    }
  },

  contact1: {
    variant: 'customVariants.homepageContentSection1',
    padding: '5vh 1rem 5vh'
  },
  contactForm: {
    order: '4',
    textAlign: 'left',
    position: 'relative',
    alignItems: 'flex-start',
    '> *': {
      zIndex: '1'
    },
    '::before': {
      content: ["''"],
      backgroundColor: ['#152613b5'],
      width: ['100%', '', '50%'],
      height: '100%',
      position: 'absolute',
      top: '0%',
      right: '0%',
      zIndex: '0'
    },

    '.title': {
      variant: 'text.title',
      color: 'white',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      maxWidth: '500px',
      width: '100%'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      maxWidth: '500px',
      width: '100%',
      opacity: '0.7',
      mb: '2rem',
      order: 'unset',
      color: 'white'
    },
    '.text': {
      maxWidth: '500px',
      width: '100%',
      mb: '2rem'
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    pl: ['', '', '52.5%'],
    form: {
      maxWidth: '500px'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '2px dashed',
      borderColor: 'secondary',
      borderRadius: '0px',
      padding: '1.5rem 0.5rem',
      color: 'white',
      fontFamily: 'heading',
      '::placeholder': {
        color: 'white'
      }
    },
    textarea: {
      '::placeholder': {
        color: 'white',
        fontWeight: '300',
        fontFamily: 'heading'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      padding: '0.5rem 1rem',
      height: 'unset',
      width: 'auto',
      marginTop: '1.5rem',
      ':hover': {
        borderColor: 'dark',
        backgroundColor: 'dark'
      }
    }
  },

  // ? ===============
  // ? === Blog ===
  // ? ===============

  blogMenu: {
    pt: ['0rem', '0rem', '0rem', '0rem', '0rem']
  },

  blogPage: {}
}

export default customVariants
